import React, { useEffect, useRef } from 'react';
import { useGetProductQuery } from '../../../redux/PharmacyServices/ProductMasterService';
import { useGetProductCategoryQuery } from '../../../redux/PharmacyServices/ProductCategoryServices';
import { useGetProductBrandQuery } from '../../../redux/PharmacyServices/ProductBrandService';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList } from '../../../Utils/helper';
import { DELETE } from '../../../icons';
import { PLUS } from '../../../icons';
import { DateInput, DropdownWithSearch } from '../../../Inputs';
import { useGetUomQuery } from '../../../redux/PharmacyServices/UomMasterService';
import RackDropdown from '../../../ReusableComponents/RackDropdown';
import BinDropdown from '../../../ReusableComponents/BinDropdown';
import useIsBarcodeSystem from '../../../CustomHooks/useIsBarcodeSystem';

const PoBillItems = ({ id, readOnly, setOpeningStockItems, openingStockItems, storeId }) => {
    const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }
    const { data: productBrandList } =
        useGetProductBrandQuery({ params });


    const isBarcode = useIsBarcodeSystem();

    const { data: productCategoryList } =
        useGetProductCategoryQuery({ params });

    const { data: productList } = useGetProductQuery({ params });

    function handleInputChange(value, index, field) {
        const newBlend = structuredClone(openingStockItems);
        newBlend[index][field] = value;
        if (field === "uomId") {
            const productId = newBlend[index]["productId"];
            let price = getProductUomPriceDetails(productId).find(i => parseInt(i.uomId) === parseInt(value))
            newBlend[index]["price"] = price?.price ? price?.price : 0
        }
        if (field === "uomId" || field === "qty" || field === "freeQty") {
            newBlend[index]['pcsQty'] = getEquilentPcs(newBlend[index]['uomId']) * (parseFloat(newBlend[index]['qty']) + parseFloat(newBlend[index]["freeQty"] || 0));
        }
        console.log(field, newBlend[index]['pcsQty']);
        setOpeningStockItems(newBlend);
    };

    function deleteRow(index) {
        setOpeningStockItems(prev => prev.filter((_, i) => i !== index))
    }
    function addNewRow() {
        setOpeningStockItems(prev => [
            ...prev,
            { productCategoryId: "", productBrandId: "", productId: "", stockQty: "0", qty: "0", price: "0.00", amount: "0.000", salePrice: '0.00', saleDiscount: "0.00" }
        ]);
    }
    useEffect(() => {
        if (openingStockItems?.length >= 5) return
        setOpeningStockItems(prev => {
            let newArray = Array.from({ length: 5 - prev.length }, i => {
                return { productCategoryId: "", productBrandId: "", productId: "", qty: "0", price: "0.00", salePrice: "0.00", saleDiscount: "0.00", amount: "0.000" }
            })
            return [...prev, ...newArray]
        }
        )
    }, [setOpeningStockItems, openingStockItems])

    function getTotal(field1, field2) {
        const total = openingStockItems?.reduce((accumulator, current) => {

            return accumulator + parseFloat(current[field1] && current[field2] ? current[field1] * current[field2] : 0)
        }, 0)
        return parseFloat(total)
    }
    function getProductUomPriceDetails(productId) {
        const items = findFromList(productId, productList?.data ? productList?.data : [], "ProductUomPriceDetails")
        return items ? items : []
    }
    const { data: uomData } = useGetUomQuery({});

    function getEquilentPcs(uomId) {
        const items = findFromList(uomId, uomData?.data ? uomData?.data : [], "equivalentPcs")
        return items ? items : 0
    }

    if (!productBrandList || !productCategoryList || !productList) return <Loader />

    return (
        <>
            <div className={` relative w-full py-1 frame`}>
                <table className=" border text-sm table-auto  w-full">
                    <thead className=''>
                        <tr className='bg-gray-800  text-white '>
                            <th className="  w-2 text-center ">S.no</th>
                            {isBarcode &&
                                <th className=" border border-white  w-28">Bar Code<span className="  w-full">*</span></th>
                            }
                            <th className=" border border-white  ">Brand</th>
                            <th className=" border border-white  ">Product<span className=" 0 ">*</span></th>
                            <th className=" border border-white   ">Batch No.</th>
                            <th className=" border border-white   min-w-16">Exp. Date</th>
                            <th className=" border border-white  w-8">Conversion<span className="  0 px-5">*</span></th>
                            <th className=" border border-white   ">Qty</th>
                            <th className=" border border-white   ">Free</th>
                            <th className=" border border-white   ">Rate</th>
                            <th className=" border border-white    ">Amt</th>
                            <th className=" border border-white   ">Mrp</th>
                            <th className=" border border-white    ">Rack</th>
                            <th className=" border border-white   ">Bin</th>
                            {!readOnly &&
                                <th className=" text-[16px]  w-10 p-0.5">  <button onClick={addNewRow}>{PLUS}</button></th>
                            }
                        </tr>
                    </thead>
                    <tbody className=''>
                        {(openingStockItems || []).map((item, index) =>
                            <tr key={index} className="w-full table-row">
                                <td className="table-data  text-center px-1 ">
                                    {index + 1}
                                </td>
                                {isBarcode &&
                                    <td className=" w-2 text-left px-1 py-1">
                                        <input
                                            type="text"
                                            className="text-right rounded py-1 px-1 w-full "
                                            value={item?.barCode}
                                            disabled={readOnly}
                                            onChange={(e) =>
                                                handleInputChange(e.target.value, index, "barCode")
                                            }
                                        />
                                    </td>
                                }

                                <td className='table-data min-w-44' >

                                    <DropdownWithSearch
                                        value={item.productBrandId}
                                        readOnly={readOnly}
                                        setValue={(value) => handleInputChange(value, index, "productBrandId")}
                                        options={productBrandList?.data ? (
                                            (id ? productBrandList?.data : productBrandList?.data.filter(i => i?.active))
                                        ) : []} />
                                </td>
                                <td className='table-data min-w-48'>
                                    <DropdownWithSearch
                                        value={item.productId}
                                        readOnly={readOnly}
                                        setValue={(value) => handleInputChange(value, index, "productId")}
                                        options={productList.data.filter(value => parseInt(value.productBrandId) === parseInt(item.productBrandId)).filter(item => item?.active)} />
                                </td>
                                <td className='border border-white'>
                                    <input
                                        type="text"
                                        className="text-right rounded py-1 px-1  table-data-input  w-16"
                                        value={item?.batchNo ? item?.batchNo : ""}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "batchNo")
                                        }
                                        onBlur={() => {
                                            if (!item.expiryDate) {
                                                handleInputChange(new Date(), index, "expiryDate")
                                            }
                                        }}
                                    />
                                </td>
                                <td className='table-data min-w-12'>
                                    <DateInput hideLabel value={item?.expiryDate ? new Date(item.expiryDate) : new Date()} setValue={(value) =>
                                        handleInputChange(value, index, "expiryDate")} dateFormat='MMM-YYYY' required={true} readOnly={readOnly} showMonthYearPicker />
                                </td>
                                <td className=''>
                                    <select
                                        disabled={readOnly}
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "uomId") } }}
                                        className='text-left w-full rounded py-1 table-data-input'
                                        value={item.uomId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "uomId")}
                                    >
                                        <option value={""}>
                                            Select
                                        </option>
                                        {getProductUomPriceDetails(item.productId).map((uom) =>
                                            <option value={uom.uomId} key={uom.uomId}>
                                                {uom?.Uom?.name}
                                            </option>)}
                                    </select>
                                </td>
                                <td className=' '>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 w-10"
                                        value={(!item.qty) ? 0 : item.qty}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "qty")
                                        }
                                        onBlur={(e) => {
                                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "qty");
                                        }
                                        }
                                        min={0} b
                                    />
                                </td>
                                <td className=''>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1  w-10"
                                        value={item?.freeQty || 0}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "freeQty")
                                        }
                                        onBlur={(e) => {
                                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "freeQty");
                                        }
                                        }
                                        min={0}
                                    />
                                </td>
                                <td className='border border-white'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 w-14"
                                        value={(!item.price) ? 0.000 : item.price}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "price")
                                        }
                                        onBlur={(e) => {
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "price");
                                        }
                                        }
                                        min={0}
                                    />
                                </td>
                                <td className=' min-w-16'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 w-12"
                                        value={(!item.qty || !item.price) ? 0 : (parseFloat(item.qty) * parseFloat(item.price)).toFixed(2)}
                                        disabled
                                        min={0}
                                    />
                                </td>
                                <td className='border border-white'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"
                                        min={0}
                                        value={(!item.salePrice) ? 0 : item.salePrice}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "salePrice")
                                        }
                                        onBlur={(e) => {
                                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "salePrice");
                                        }
                                        }
                                    />
                                </td>
                                <td className=' min-w-16'>
                                    <RackDropdown multiSelect={false}
                                        storeId={storeId}
                                        readOnly={(!storeId) || readOnly}
                                        selected={item?.rackId}
                                        setSelected={(value) => handleInputChange(value, index, "rackId")}
                                    />
                                </td>
                                <td className=' min-w-16'>
                                    <BinDropdown multiSelect={false}
                                        rackId={item?.rackId}
                                        readOnly={(!item?.rackId) || readOnly}
                                        selected={item?.binId}
                                        setSelected={(value) => handleInputChange(value, index, "binId")}
                                    />
                                </td>
                                {!readOnly &&
                                    <td className="text-xs text-center">
                                        <button
                                            type='button'
                                            onClick={() => {
                                                deleteRow(index)
                                            }}
                                            className='text-xs text-red-600 '>{DELETE}
                                        </button>
                                    </td>
                                }
                            </tr>
                        )}
                        <tr className='w-full   font-bold p-2'>
                            <td className="text-[16px] text-center  w-10 font-bold" colSpan={10}>Total</td>
                            <td className=" text-right pr-1 w-10">{getTotal("qty", "price").toFixed(2)} </td>
                            <td className="table-data text-center w-10 font-bold" colSpan={readOnly ? 5 : 4}></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PoBillItems