import React, { useEffect, useState } from 'react';
import secureLocalStorage from "react-secure-storage";
import { useGetStockQuery } from '../../../redux/PharmacyServices/StockService';
import { EMPTY_ICON, REFRESH_ICON } from "../../../icons";
import moment from 'moment';
import Modal from '../../../UiComponents/Modal';
import Parameter from './Parameter';
import ParameterButton from '../../../ReusableComponents/ParameterButton';
import { getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import { Loader } from '../../../Basic/components';
import ReactPaginate from 'react-paginate';
import { showEntries } from '../../../Utils/DropdownData';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { TablePagination } from '@mui/material';

const StockReport = () => {
  const [storeId, setStoreId] = useState("");
  const [locationId, setLocationId] = useState('');
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [parameter, setParameter] = useState(false);
  const [searchSupplierName, setSearchSupplierName] = useState("");
  const [dataPerPage, setDataPerPage] = useState("15");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchRack, setSearchRack] = useState('');
  const [searchBin, setSearchBin] = useState('');
  const [searchProduct, setSearchProduct] = useState('');
  const [searchUom, setSearchUom] = useState('')

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  );
  const searchFields = { searchProduct, searchRack, searchSupplierName, searchUom, searchBin, }

  const { data: stockData, refetch, isLoading, isFetching } = useGetStockQuery(
    { params: { branchId, stockReport: true, storeId, toDate: endDate, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber + 1 } },
    { skip: !(endDate && storeId) }
  );
  const handleOnclick = (e) => {
    setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
  }
  useEffect(() => { setCurrentPageNumber(0) }, [dataPerPage, searchProduct, searchRack, searchSupplierName, searchUom, searchBin])

  const isLoadingIndicator = isLoading || isFetching
  useEffect(() => {
    if (stockData?.totalCount) {
      setTotalCount(stockData?.totalCount)
    }
  }, [stockData, isLoading, isFetching])

  useEffect(() => {
    const registrationFormReportComponent = document.getElementById("registrationFormReport");

    if (registrationFormReportComponent) {
      const handleKeyDown = (ev) => {
        const focusableElementsString = '[tabindex="0"]';
        const ol = document.querySelectorAll(focusableElementsString);

        if (ev.key === "ArrowDown") {
          for (let i = 0; i < ol.length; i++) {
            if (ol[i] === ev.target) {
              const o = i < ol.length - 1 ? ol[i + 1] : ol[0];
              o.focus();
              break;
            }
          }
          ev.preventDefault();
        } else if (ev.key === "ArrowUp") {
          for (let i = 0; i < ol.length; i++) {
            if (ol[i] === ev.target) {
              const o = ol[i - 1];
              o.focus();
              break;
            }
          }
          ev.preventDefault();
        }
      };

      registrationFormReportComponent.addEventListener('keydown', handleKeyDown);

      return () => {
        registrationFormReportComponent.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, []);

  let stockList = stockData ? stockData.data : [];
  const handleChange = (event, value) => {
    setCurrentPageNumber(value);
  };
  const handleChangeRowsPerPage = (event) => {
    setDataPerPage(parseInt(event.target.value, 10));
    setCurrentPageNumber(0);
  };
  return (
    <>
      <Modal isOpen={parameter} onClose={() => setParameter(false)}>
        <Parameter
          locationId={locationId} setLocationId={setLocationId}
          storeId={storeId} setStoreId={setStoreId}
          endDate={endDate} setEndDate={setEndDate}
          onClose={() => setParameter(false)}
        />
      </Modal>

      <div className=''>
        <div className='w-full  p-2'>
          <div className='flex items-center justify-between page-heading p-2 font-bold'>
            <h1>Stock Report as On Date</h1>
            <div className='flex gap-5'>

              <ParameterButton onClick={() => setParameter(true)} />
              <button onClick={refetch}>
                Refresh {REFRESH_ICON}
              </button>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button text-white bg-blue-400 p-2 text-xs rounded-xl"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText='Download to excel'
              >

              </ReactHTMLTableToExcel>

            </div>
          </div>





          <div className='overflow-x-auto overflow-auto  mt-3'>

            <table className="w-full text-xs text-left border border-White border-collapse">
              <thead className="text-xs uppercase text-white bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
                <tr className='sticky top-0  font-thin tracking-wider'>
                  <th
                    className=" p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    S. no.
                  </th>
                  <th
                    className=" p-2 text-center  border border-White font-semibold  top-0 stick-bg flex flex-col"
                  >
                    <div>Product</div>

                    <input type="text"
                      className={`text-black h-6 focus:outline-none border md:ml-3 border-white rounded-lg }`}
                      placeholder="Search"
                      value={searchProduct}
                      onChange={(e) => setSearchProduct(e.target.value)}
                    />
                  </th>
                  <th
                    className="border-2 p-2 text-center  border border-White font-semibold  top-0 stick-bg"
                  >
                    <div>Location</div>
                  </th>

                  <th
                    className=" p-2 text-center  border border-White font-semibold  top-0 stick-bg"
                  >
                    <div>Store</div>

                  </th>
                  <th
                    className=" p-2 text-center  border border-White font-semibold  top-0 stick-bg"
                  >
                    <div>Rack</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-white rounded-lg"
                      placeholder="Search"
                      value={searchRack}
                      onChange={(e) => {
                        setSearchRack(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className=" p-2 text-center  border border-White font-semibold  top-0 stick-bg"
                  >
                    <div>Bin</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-white rounded-lg "
                      placeholder="Search"
                      value={searchBin}
                      onChange={(e) => {
                        setSearchBin(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className=" p-2 text-center  border border-White font-semibold  top-0 stick-bg"
                  >
                    <div>UOM</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-white rounded-lg"
                      placeholder="Search"
                      value={searchUom}
                      onChange={(e) => {
                        setSearchUom(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className=" p-2 text-center  border border-White font-semibold  top-0 stick-bg"
                  >
                    <div>Stock Qty</div>
                  </th>
                </tr>
              </thead>
              {isLoadingIndicator ?
                <tbody>
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                </tbody>
                :
                <tbody className="">
                  {stockList.map((dataObj, index) => (

                    <tr
                      className={` table-row `}>
                      <td className='py-1'> {(index + 1) + (dataPerPage * (currentPageNumber))}</td>

                      <td className='p-2 text-[13px] text-left border border-white '>{dataObj.Product}</td>
                      <td className='p-2 text-[13px] text-left border border-white '> {dataObj?.Location}</td>
                      <td className='p-2 text-[13px] text-left border border-white '>{dataObj?.Store} </td>
                      <td className='p-2 text-[13px] text-left border border-white '>{(dataObj?.Rack
                      )}</td>
                      <td className='p-2 text-[13px] text-left border border-white '>{(dataObj?.Bin
                      )}</td>
                      <td className='p-2 text-[13px] text-left border border-white '>{dataObj.Uom}</td>
                      <td className='p-2 text-[13px] text-right border border-white '>{dataObj.StockQty}</td>

                    </tr>


                  ))}
                </tbody>

              }
            </table>
            {/*Below table excel export */}
            <table id="table-to-xls" className="table-fixed text-center w-full mt-2 visible">
              <thead className="border-2 table-header">
                <tr className='h-2'>
                  <th
                    className="border-2  top-0 stick-bg w-10"
                  >
                    S. no.
                  </th>
                  <th
                    className="border-2  top-0 stick-bg flex flex-col"
                  >
                    <div>Product</div>


                  </th>
                  <th
                    className="border-2  top-0 stick-bg"
                  >
                    <div>Location</div>
                  </th>

                  <th
                    className="border-2  top-0 stick-bg"
                  >
                    <div>Store</div>

                  </th>
                  <th
                    className="border-2  top-0 stick-bg"
                  >
                    <div>Rack</div>
                  </th>
                  <th
                    className="border-2  top-0 stick-bg"
                  >
                    <div>Bin</div>
                  </th>
                  <th
                    className="border-2  top-0 stick-bg"
                  >
                    <div>UOM</div>
                  </th>
                  <th
                    className="border-2  top-0 stick-bg"
                  >
                    <div>Stock Qty</div>
                  </th>
                </tr>
              </thead>
              {isLoadingIndicator ?
                <tbody>
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                </tbody>
                :
                <tbody className="">
                  {stockList.map((dataObj, index) => (

                    <tr
                      className={` table-row `}>
                      <td className='py-1'> {(index + 1) + (dataPerPage * (currentPageNumber))}</td>

                      <td className='p-2 text-left border border-white '>{dataObj.Product}</td>
                      <td className='p-2 text-left border border-white '> {dataObj?.Location}</td>
                      <td className='p-2 text-left border border-white '>{dataObj?.Store} </td>
                      <td className='p-2 text-left border border-white '>{(dataObj?.Rack
                      )}</td>
                      <td className='p-2 text-left border border-white '>{(dataObj?.Bin
                      )}</td>
                      <td className='p-2 text-left border border-white '>{dataObj.Uom}</td>
                      <td className='p-2 text-right border border-white '>{dataObj.StockQty}</td>

                    </tr>


                  ))}
                </tbody>

              }
            </table>
            <div className='flex items-center justify-end'>
              <TablePagination
                component="div"
                count={totalCount}
                page={currentPageNumber}
                onPageChange={handleChange}
                rowsPerPage={dataPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockReport;
