import React, { useEffect } from 'react';
import { FaRegFilePdf } from 'react-icons/fa';

const PrintFormatViewer = ({ pdfUrl, setPdfUrl, id, reportName, parameter }) => {
    const database = "hospitalmanagementsystem";
    const project = "HMS";
    const viewer = `${process.env.REACT_APP_VIEWER_URL}?database=${database}&reportName=${reportName}&project=${project}&${parameter}`;
    const excel = viewer + "&fileType=excel";

    useEffect(() => {
        // Fetch the PDF and set the URL to display it
        const previewPdf = (viewer) => {
            fetch(viewer)
                .then(response => response.blob())
                .then(blob => {
                    const blobUrl = URL.createObjectURL(blob);
                    setPdfUrl(blobUrl); // Set the blob URL for PDF
                })
                .catch(error => {
                    console.error('Error fetching PDF:', error);
                });
        };
        if (id) {
            previewPdf(viewer);
        }
    }, [id, setPdfUrl,]);

    console.log(pdfUrl, 'pdfUrl')
    return (
        <div>
            {pdfUrl && (
                < div className='relative'>
                    <div className="flex justify-end gap-5   absolute right-32 top-4">
                        <a className=" text-red-500 e p-1 rounded" title='PDF' download href={viewer}><FaRegFilePdf /></a>
                        <a className=" text-green-500 p-1 rounded" title='Excel' download href={excel}><FaRegFilePdf /> </a>
                    </div>
                    <iframe
                        src={pdfUrl}
                        width="100%"
                        height="670x"
                        style={{ border: 'none' }}
                        title="PDF Viewer"
                    />
                </div>
            )}
        </div>
    );
};

export default PrintFormatViewer;
