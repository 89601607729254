import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useOutsideClick from '../CustomHooks/handleOutsideClick'
import { getCommonParams, getDateFromDateTimeToDisplay } from '../Utils/helper'
import { TextInput } from '../Inputs'
import { useGetStockQuery } from '../redux/PharmacyServices/StockService'

const StockItemOptionForBarCode = ({ dropDownOnly = false, readOnly, storeId, setPoBillItems }) => {
    const { branchId } = getCommonParams()
    const [barCode, setBarCode] = useState("");
    const [currentSelectedStockItem, setCurrentSelectedStockItem] = useState('');
    const [fetchProductFromBarCode, setFetchProductFromBarCode] = useState(false);
    const [isOpen, setIsOpen] = useState(false)

    const { data: productData, isLoading: productLoading, isFetching: productFetching } = useGetStockQuery({ params: { barCode, storeId, branchId } }, { skip: (!barCode) || (!fetchProductFromBarCode) })
    console.log(productData, 'productData');

    const options = useMemo(() => productData?.data?.stockDetails || [], [productData]);

    const handleBarCodeSubmit = useCallback((currentSelectedStockItem) => {
        if (productFetching || productLoading || !productData?.data) return

        const productItem = {
            ...productData?.data,
            stockItem: currentSelectedStockItem,
            salePrice: options.find(i => i.stockItem === currentSelectedStockItem)?.salePrice,
            binId: options.find(i => i.stockItem === currentSelectedStockItem)?.binId,
            batchNo: options.find(i => i.stockItem === currentSelectedStockItem)?.batchNo,
            expiryDate: options.find(i => i.stockItem === currentSelectedStockItem)?.expiryDate,
            purchasePrice: options.find(i => i.stockItem === currentSelectedStockItem)?.purchasePrice,
            supplierId: options.find(i => i.stockItem === currentSelectedStockItem)?.supplierId,
            pcsQty: 1
        }
        setPoBillItems((prev) => {
            let newItems = structuredClone(prev);
            if (prev.every(i => i?.productId)) {
                newItems = [...newItems, productItem]
            } else {
                const index = prev.findIndex(i => !Boolean(i?.productId));
                newItems[index] = productItem
            }
            return newItems
        })
        setBarCode("");
        setFetchProductFromBarCode(false);
    }, [setPoBillItems, productData, productLoading, productFetching, options])

    useEffect(() => {
        if (barCode && fetchProductFromBarCode && (options.length === 1)) {
            handleBarCodeSubmit(options[0]?.stockItem)
        }
    }, [setPoBillItems, currentSelectedStockItem, options, barCode, fetchProductFromBarCode, handleBarCodeSubmit])
    const ref = useOutsideClick(() => {
        setIsOpen(false)
    })
    const headers = ["Price", "Bin", "Rack", "Bat.no", "Exp.Date", "Supplier", "Stock Qty"]
    const data = ["salePrice", "Bin", "Rack", "batchNo", "expiryDate", "supplierName", "stockQty"]
    useEffect(() => {
        const dropDownElement = document.getElementById("dropdown");
        dropDownElement.addEventListener('keydown', function (ev) {
            var focusableElementsString = '[tabindex="0"]';
            let ol = dropDownElement.querySelectorAll(focusableElementsString);
            if (ev.key === "ArrowDown") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            } else if (ev.key === "ArrowUp") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = ol[i - 1];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            }
        });

        return () => {
            dropDownElement.removeEventListener('keydown', () => { });
        };
    }, []);
    return (
        <div className='w-full relative ' id='dropdown' ref={ref}>
            {!dropDownOnly &&
                <TextInput name="Bar Code" value={barCode} setValue={setBarCode} readOnly={readOnly}
                    tabIndex={0}
                    className={'bold'}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            setFetchProductFromBarCode(true);
                            setIsOpen(true);
                        }
                    }} />
            }
            {isOpen &&
                <div className='absolute -left-[50px] w-full z-30 overflow-auto max-h-[100px] bg-gray-100'>
                    <table className='text-sm w-full border-2 border-gray-500'>
                        <thead className='w-full sticky z-50 top-0' >
                            <tr className='table-row'>
                                {headers.map(item =>
                                    <th key={item} className='border-2 border-gray-500'>
                                        {item}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {options.map(item =>
                                <tr key={item.stockItem} tabIndex={0} onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleBarCodeSubmit(item.stockItem)
                                        setIsOpen(false)
                                    }
                                }
                                }
                                    onClick={() => {
                                        setCurrentSelectedStockItem(item.stockItem)
                                        handleBarCodeSubmit(item.stockItem)
                                        setIsOpen(false)
                                    }} className='table-row' >
                                    {data.map(i =>
                                        <td key={i} className='table-data text-center border-2 border-gray-500'>
                                            {i === 'expiryDate'
                                                ?
                                                getDateFromDateTimeToDisplay(item[i])
                                                :
                                                item[i]
                                            }
                                        </td>
                                    )
                                    }
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default StockItemOptionForBarCode