import React from 'react'
import { DropdownInput } from '../Inputs'

const ProductType = ({ productType, setProductType, readOnly, childRecord }) => {
    const options = [
        { show: 'TABLET', value: 'TABLET' },
        { show: 'CAPSULE', value: 'CAPSULE' },
        { show: 'SYRUP', value: 'SYRUP' },
        { show: 'JELL', value: 'JELL' },
        { show: 'SPRAY', value: 'SPRAY' },
        { show: 'INJECTION', value: 'INJECTION' },
        { show: 'BELT', value: 'BELT' },
        { show: 'OINMENT', value: 'OINMENT' },
        { show: 'SALINE', value: 'SALINE' },
        { show: 'BELT', value: 'BELT' },
    ]
    return (
        <DropdownInput name="Product Type" options={options} value={productType} setValue={setProductType} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
    )
}

export default ProductType