import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';

import FormHeader from '../../../Basic/components/FormHeader';
import { toast } from "react-toastify"
import { DisabledInput, TextInput, DropdownInput } from "../../../Inputs"
import ReportTemplate from '../../../Basic/components/ReportTemplate';
import {
  useGetPaymentQuery,
  useGetPaymentByIdQuery,
  useAddPaymentMutation,
  useUpdatePaymentMutation,
  useDeletePaymentMutation,
} from '../../../redux/PharmacyServices/PaymentService'
import qr from '../../../assets/qrcode.jpg'
import { getCommonParams, getDateFromDateTime } from '../../../Utils/helper';
import Modal from "../../../UiComponents/Modal";
import PurchaseBillFormReport from './PurchaseBillFormReport';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { filterByPatientType, paymentModes } from '../../../Utils/DropdownData';
import PatientConsultedSelection from './PatientConsultedSelection';
import { useGetPatientVisitByIdQuery } from '../../../redux/pharmaServices/PatientVisitTransaction';
import { getImageUrlPath, PAYMENT_PAGE_NAME } from '../../../Constants';
import { DELETE, EMPTY_PROFILE_IMAGE, PLUS } from '../../../icons';
import { push } from '../../../redux/features/opentabs';
import PaymentSuccessModal from './PaymentSuccessModal ';
import BarcodeGenerator from '../BarCodeGenerator';
import UPIQrCode from '../UPIQrCode';
import { PDFViewer } from '@react-pdf/renderer';
import PrintFormatPayment from '../PrintFormatPayment';
import tw from '../../../Utils/tailwind-react-pdf';
import InPatientForm from './InPatientForm';
import CounterForm from './CounterForm';
import { useGetLabTestMasterQuery } from '../../../redux/pharmaServices/LabTestMaster';
import secureLocalStorage from 'react-secure-storage';
import { useGetLabTestTypesQuery } from '../../../redux/pharmaServices/LabTestTypes';
import { useGetUserQuery } from '../../../redux/services/UsersMasterService';
import { useGetPatientAdmitByIdQuery } from '../../../redux/pharmaServices/PatientAdmitTransaction';
import { dropDownListObject } from '../../../Utils/contructObject';
import { useGetEmployeeQuery } from '../../../redux/services/EmployeeMasterService';

const MODEL = "Payments";

export default function Form() {

  const openTabs = useSelector((state) => state.openTabs);
  const previewId = useMemo(() => openTabs.tabs.find(i => i.name === PAYMENT_PAGE_NAME)?.previewId, [openTabs]);


  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId")


  const [barCode, setBarCode] = useState("")
  const today = new Date()
  const [form, setForm] = useState(true);
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [docId, setDocId] = useState("");
  const [formReport, setFormReport] = useState(false)
  const [patientVisitPopUp, setPatientVisitPopUp] = useState(false);
  const [patientAdmitId, setPatientAdmitId] = useState('');
  const [needToPay, setNeedToPay] = useState('');
  const [patientVisitId, setPatientVisitId] = useState('');

  const [id, setId] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [consultFee, setConsultFee] = useState('');
  const [refNo, setRefNo] = useState('');
  const [paymentMode, setPaymentMode] = useState('')
  const [printModalOpen, setPrintModalOpen] = useState(false);

  const [previewOption, setPreviewOption] = useState(false)
  const [filterByPatient, setFilterByPatient] = useState("OUTPATIENT")
  const [searchValue, setSearchValue] = useState("");
  const childRecord = useRef(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feesStructureGrid, setFeesStructureGrid] = useState([])
  const [showQrPaymentQrCode, setShowQrPaymentQrCode] = useState(false);
  console.log(feesStructureGrid, 'feesStructureGrid');

  const handlePaymentSuccess = () => {

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const { finYearId, branchId } = getCommonParams();

  const dispatch = useDispatch()
  const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }


  const { data: allData, isLoading, isFetching } = useGetPaymentQuery({ params: { branchId, finYearId, patientVisitId, patientAdmitId }, searchParams: searchValue });
  const { data: singleData } = useGetPaymentByIdQuery(id, { skip: !id });

  const { data: labTestDetailsData } =
    useGetLabTestMasterQuery({ params });

  const { data: labTestTypeData } =
    useGetLabTestTypesQuery({ params });

  const { data: userData } = useGetUserQuery({ params });

  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false);
      if (data?.docId) {
        setDocId(data.docId);
      }
      setPaymentMode(data?.paymentMode || '')
      setRefNo(data?.refNo || '')
      if (data?.createdAt) setDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
      setPatientVisitId(data?.patientVisitId || '');
      setConsultFee(data?.consultFee || 0);
      setPatientAdmitId(data?.patientAdmitId || '');
      setNeedToPay(data?.needToPay || 0);
      setFeesStructureGrid(data?.feesStructureGrid ? data?.feesStructureGrid : [])
      childRecord.current = data?.childRecord ? data?.childRecord : 0;
      console.log(patientAdmitId, "patientAdmitIdpatientAdmitIdpatientAdmitId")
    }, [id])


  useEffect(() => {
    if (id) return
    syncFormWithDb(undefined)
  }, [id, filterByPatient, syncFormWithDb])

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
    if (singleData?.data?.patientType) {
      setFilterByPatient(singleData?.data?.patientType)
    }
  }, [syncFormWithDb, singleData])


  useEffect(() => {
    if (!previewId) return
    setPatientVisitId(previewId);
    setPreviewOption(true);
    dispatch(push({ name: PAYMENT_PAGE_NAME, previewId: null }))
  }, [previewId, dispatch])

  const { data: patientVisitData } = useGetPatientVisitByIdQuery(patientVisitId, { skip: !patientVisitId })
  const { data: patientAdmitData } = useGetPatientAdmitByIdQuery(patientAdmitId, { skip: !patientAdmitId })

  const { data: doctorList } = useGetEmployeeQuery({ params: { branchId, employeeCategory: "DOCTOR", active: true } });
  console.log(doctorList, 'doctorList');

  useEffect(() => {
    if (id) return
    if (!patientVisitData?.data?.PatientVisitPayment?.id) return
    setId(patientVisitData?.data?.PatientVisitPayment?.id)
  }, [patientVisitData, id])


  const [addData] = useAddPaymentMutation();
  const [updateData] = useUpdatePaymentMutation();
  const [removeData] = useDeletePaymentMutation();
  const getNextDocId = useCallback(() => {

    if (id || isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const data = {
    id,
    branchId,
    patientVisitId,
    consultFee,
    paymentMode,
    refNo,
    isConsultFeePaid: true,
    finYearId,
    patientType: filterByPatient,
    patientAdmitId,
    feesStructureGrid
  }

  // const updateConsultFee = useCallback(() => {
  //   if (!patientVisitData?.data) return
  //   setConsultFee(patientVisitData?.data?.consultFee || 0);
  // }, [patientVisitData])

  // useEffect(() => {
  //   if (id) return
  //   updateConsultFee()
  // }, [updateConsultFee, id])


  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        setId("")
        syncFormWithDb(undefined)
        // toast.success(text + "Successfully");
        handlePaymentSuccess()
        setPreviewOption(false)
      } else {
        toast.error(returnData?.message)
      }
      dispatch({
        type: `patientVisit/invalidateTags`,
        payload: ['PatientVisit'],
      });
    } catch (error) {
      console.log("handle")
    }

  }


  const saveData = () => {
    if (filterByPatient == "INPATIENT") {
      if (!data.patientAdmitId) {
        toast.info("Choose Patient Admit...!", { position: "top-center" })
        return
      }

      if (!data.consultFee) {
        toast.info("Amount is Empty...!", { position: "top-center" })
        return
      }
      if (!window.confirm("Are you sure save the details ...?")) {
        return
      }
      if (id) {
        handleSubmitCustom(updateData, data, "Updated")
      } else {
        handleSubmitCustom(addData, data, "Added")
      }
    }
    else {
      if (!data.patientVisitId) {
        toast.info("Choose Patient Visit...!", { position: "top-center" })
        return
      }
      if (!data.paymentMode) {
        toast.info("Choose Payment Mode...!", { position: "top-center" })
        return
      }
      if (!data.refNo) {
        toast.info("Add Ref No...!", { position: "top-center" })
        return
      }


      if (!window.confirm("Are you sure save the details ...?")) {
        return
      }
      if (id) {
        handleSubmitCustom(updateData, data, "Updated")
      } else {
        handleSubmitCustom(addData, data, "Added")
      }
    }
  }
  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        let returnData = await removeData(id).unwrap();
        if (returnData?.statusCode === 1) {
          return toast.error(returnData?.message);
        }
        else {
          setId("");
          toast.success("Deleted Successfully");
          dispatch({
            type: `patientVisit/invalidateTags`,
            payload: ['PatientVisit'],
          });
        }

      } catch (error) {
        toast.error("something went wrong")
      }
      ;
    }
  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }
  }

  const onNew = () => {
    setId("");
    getNextDocId();
    setReadOnly(false);
    setForm(true);
    setSearchValue("")
    syncFormWithDb(undefined);
    setPreviewOption(false);
  }



  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }


  const handleInputFees = (value, index, key) => {
    const updatedGrid = [...feesStructureGrid];

    updatedGrid[index][key] = value;
    console.log(value, 'updatedGrid');

    if (key === "employeeId") {
      const selectedEmployee = doctorList?.data?.find((doc) => doc.id === parseInt(value));
      if (selectedEmployee) {
        updatedGrid[index]["amount"] = selectedEmployee.consultFee || 0;
      } else {
        updatedGrid[index]["amount"] = 0;
      }
    }

    setFeesStructureGrid(updatedGrid);
  };


  useEffect(() => {
    let totalAmount = feesStructureGrid?.reduce((a, b, index) => a + parseFloat(b.amount), 0)

    setConsultFee(parseFloat(totalAmount).toFixed(2))

  }, [setFeesStructureGrid, feesStructureGrid])


  function deleteGrid(index) {
    setFeesStructureGrid(prev => prev.filter((_, i) => i !== index))
  }
  function addNewGrid() {
    setFeesStructureGrid(prev =>
      [...prev,
      { amount: "", labTestId: "", labTestTypeId: "" }]
    );
  }


  useEffect(() => {
    if (id) return
    setFeesStructureGrid([])
  }, [patientVisitData, id])

  useEffect(() => {
    if (id) return;
    if (!labTestDetailsData || !patientVisitData) return;
    if (feesStructureGrid.length >= 1) return;

    console.log(labTestDetailsData, 'labTestDetailsData');

    let findConsultFeesId = labTestDetailsData?.data?.find(val => val.isConsultFees)?.id;
    let findConsultFeesTypeId = labTestDetailsData?.data?.find(val => val.isConsultFees)?.labTestTypeId;
    let consultFees = 0;
    let doctors = [];

    if (patientVisitData?.data) {
      // consultFees = patientVisitData?.data?.consultFee || 0;
      const primaryDoctorId = patientVisitData?.data?.employeeId || 0;
      const referredDoctorId = patientVisitData?.data?.referredDoctorId || 0;

      doctors = referredDoctorId ? [primaryDoctorId, referredDoctorId] : [primaryDoctorId];
    }

    setFeesStructureGrid(prev => {
      let newArray = doctors.map(doctorId => ({
        amount: '',
        employeeId: doctorId,
        labTestId: findConsultFeesId,
        labTestTypeId: findConsultFeesTypeId
      }));

      return [...prev, ...newArray];
    });
  }, [setFeesStructureGrid, feesStructureGrid, labTestDetailsData, patientVisitData, id]);


  const handlePrint = () => {
    setPrintModalOpen(true);
  }
  const tableHeaders = [
    "Code", "Name", "Status"
  ]
  const tableDataNames = ["dataObj.code", "dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']


  console.log(singleData, "singleee")

  if (!form)
    return <ReportTemplate
      heading={MODEL}
      tableHeaders={tableHeaders}
      tableDataNames={tableDataNames}
      loading={
        isLoading || isFetching
      }
      setForm={setForm}
      data={allData?.data ? allData?.data : []}
      onClick={onDataClick}
      onNew={onNew}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />




  return (

    <div onKeyDown={handleKeyDown} className=' flex  justify-center items-center overflow-y-auto '>{console.log(patientAdmitId, "patientAdmitId", patientVisitId, "patientvisitid")}
      <Modal isOpen={printModalOpen} onClose={() => setPrintModalOpen(false)} widthClass={"w-[90%] h-[90%]"} >
        <PDFViewer style={tw("w-full h-full")}>
          <PrintFormatPayment barCode={barCode} setBarCode={setBarCode} data={singleData?.data ? singleData.data : undefined} patientVisitData={patientVisitData} userId={userId} userData={userData} />
        </PDFViewer>
      </Modal>

      <Modal

        isOpen={formReport}
        onClose={() => setFormReport(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}

      >
        <PurchaseBillFormReport onClick={(id) => { setId(id); setFormReport(false) }} filterByPatient={filterByPatient} patientVisitId={patientVisitId} patientAdmitId={patientAdmitId} />
      </Modal>
      <Modal
        isOpen={showQrPaymentQrCode}
        onClose={() => setShowQrPaymentQrCode(false)}
        widthClass={"px-2"}
      >
        <div className="">
          <UPIQrCode key={consultFee} amount={consultFee} />
        </div>
      </Modal>
      <Modal

        isOpen={patientVisitPopUp}
        onClose={() => setPatientVisitPopUp(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <PatientConsultedSelection onClick={(visitId) => { setPatientVisitId(visitId); setPatientVisitPopUp(false) }} patientVisitPaymentId={id} />
      </Modal>
      <div className='flex flex-col frame w-full h-full'>
        <FormHeader
          // isFilterPatient={true}
          onNew={onNew}
          model={MODEL}
          openReport={() => setFormReport(true)}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onPrint={id ? handlePrint : null}
          filterByPatient={filterByPatient}
          setFilterByPatient={setFilterByPatient}
        />

        {/* {
          filterByPatient == "COUNTER" &&
          <CounterForm patientAdmitId={patientAdmitId} setPatientAdmitId={setPatientAdmitId} needToPay={needToPay}
            setNeedToPay={setNeedToPay} consultFee={consultFee} setConsultFee={setConsultFee} docId={docId} date={date}
            setShowQrPaymentQrCode={setShowQrPaymentQrCode} paymentMode={paymentMode} setPaymentMode={setPaymentMode}
            refNo={refNo} setRefNo={setRefNo} id={id} setId={setId} paymentData={allData} filterByPatient={filterByPatient} setFilterByPatient={setFilterByPatient} />
        } */}

        {
          filterByPatient == "OUTPATIENT" &&
          <div className="flex justify-center h-[100%] items-center ">
            <div onSubmit={saveData} className="bg-white p-2 rounded-lg w-[90%] h-full shadow-lg  border border-gray-200">
              <div className="grid grid-cols-1 ">
                <h2 className="text-3xl font-bold text-center text-emerald-700">
                  Payment
                </h2>
                <div className="flex w-full">
                  <div className="flex-grow">
                    <div className="grid grid-cols-3 gap-1">
                      <div className='col-span-3'>
                        <fieldset className='frame my-3 '>
                          <legend className='sub-heading'>Payment Details</legend>
                          <div className="flex  gap-5 p-3">
                            <div className="col-span-1 pb-2 w-60">
                              <DisabledInput name="Payment No" className='w-full' value={docId} readOnly={readOnly} />
                            </div>
                            <div className="col-span-1 w-44 pb-2">
                              <DisabledInput
                                name="Payment Date"
                                className='w-full'
                                value={date}
                                type="Date"
                                readOnly={readOnly}
                              />
                            </div>

                            <div className="w-44">
                              {/* <label className={`md:text-start text-xs flex mb-1 uppercase `}>Patient Type</label>
                              <select
                                disabled={patientVisitId || patientAdmitId}
                                name="name" className={`${"input-field border border-gray-500 p-1  md:col-span-2 col-span-1 rounded text-xs"} `}
                                value={filterByPatient} onChange={(e) => { setFilterByPatient(e.target.value); }} >
                                
                                {filterByPatientType?.map((option, index) => <option key={index} value={option.value} >
                                  {option.show}
                                </option>)}
                              </select> */}
                              <DropdownInput name="Patient Type" options={dropDownListObject(filterByPatientType ? filterByPatientType : "", "show", "value")} value={filterByPatient} setValue={setFilterByPatient} readOnly={readOnly} />
                            </div>
                            {console.log(filterByPatientType, 'filterByPatientType')
                            }


                            {
                              !previewOption

                              &&

                              <>
                                <div className="flex items-center justify-center">
                                  <button
                                    className="bg-green-800 hover:bg-green-700 uppercase p-1 rounded-md w-40 text-white text-sm"
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        setPatientVisitPopUp(true);
                                      }
                                    }}
                                    onClick={() => {
                                      if (!readOnly) {
                                        setPatientVisitPopUp(true);
                                      }
                                    }}
                                  >
                                    Choose Patient Visit
                                  </button>
                                </div>
                              </>
                            }

                          </div>
                        </fieldset>
                      </div>

                      {
                        patientVisitId &&

                        <div className="col-span-3">
                          <fieldset className="frame my-1 flex w-full space-x-4 p-4overflow-y-auto">
                            <legend className="sub-heading">Patient Visit Details</legend>
                            <div className="flex gap-4 w-[60%] p-3">
                              {/* Left section for patient image and barcode */}
                              <div className="w-[15%] rounded-lg shadow-md flex flex-col items-center">
                                <div className={`${patientVisitData?.data?.Patient?.regNo ? "h-[75%]" : "h-full"} p-3 w-[140px]`}>
                                  {patientVisitData?.data?.Patient?.image ? (
                                    <img
                                      className="w-full h-full object-cover rounded-lg"
                                      src={getImageUrlPath(patientVisitData?.data?.Patient?.image)}
                                      alt="Patient"
                                    />
                                  ) : (
                                    <div className="flex items-center justify-center w-full h-full bg-gradient-to-r from-gray-200 to-gray-300 rounded-lg">
                                      <EMPTY_PROFILE_IMAGE height="100%" width="100%" />
                                    </div>
                                  )}
                                  {patientVisitData?.data?.Patient?.regNo && (
                                    <div className="h-[25%]">
                                      <BarcodeGenerator isUi value={patientVisitData?.data?.Patient?.regNo} />
                                    </div>
                                  )}
                                </div>
                              </div>


                              <div className="w-full grid grid-cols-2 gap-4">
                                <div className='flex gap-2'>  <DisabledInput
                                  name="Token No"
                                  className="w-full"
                                  value={patientVisitData?.data?.token || ""}
                                  required
                                  readOnly={readOnly}
                                  tabIndex={1}
                                />
                                  <DisabledInput
                                    name="Patient Id"
                                    className="w-full"
                                    value={patientVisitData?.data?.Patient?.regNo || ""}
                                    required
                                    readOnly={readOnly}
                                    tabIndex={2}
                                  /></div>
                                <DisabledInput
                                  name="Patient"
                                  className="w-full"
                                  value={patientVisitData?.data?.Patient?.name || ""}
                                  required
                                  readOnly={readOnly}
                                  tabIndex={3}
                                />
                                <DisabledInput
                                  name="Patient Ph. No"
                                  className="w-full"
                                  value={patientVisitData?.data?.Patient?.mobile || ""}
                                  required
                                  readOnly={readOnly}
                                  tabIndex={4}
                                />
                                {/* <DisabledInput
                                  name="Doctor"
                                  className="w-full"
                                  value={patientVisitData?.data?.Employee?.name || ""}
                                  required
                                  readOnly={readOnly}
                                  tabIndex={5}
                                /> */}
                                <TextInput
                                  name="Total.Fees"
                                  className="w-full"
                                  type="number"
                                  min={0}
                                  value={consultFee}
                                  readOnly
                                  tabIndex={6}
                                />
                                <DropdownInput
                                  name="Pay Mode"
                                  className="text-sm w-full"
                                  options={paymentModes}
                                  value={paymentMode}
                                  setValue={setPaymentMode}
                                  required
                                  readOnly={readOnly}
                                  tabIndex={7}
                                />
                                <TextInput
                                  name="Ref No"
                                  className="w-full"
                                  type="text"
                                  value={refNo}
                                  setValue={setRefNo}
                                  readOnly={readOnly}
                                  tabIndex={9}
                                />
                                {!readOnly && paymentMode === "Upi" && (
                                  <button
                                    className="bg-blue-600 text-white h-12 rounded-sm w-full mt-2"
                                    onClick={() => setShowQrPaymentQrCode(true)}
                                    tabIndex={8}
                                  >
                                    Show QR Code
                                  </button>
                                )}
                              </div>
                            </div>


                            <div className="w-[40%] p-4 overflow-y-scroll h-[90%]">
                              <div className="sub-heading mt-3 ml-1">Fees Structure</div>
                              <table className="border border-gray-500 text-xs mt-2 w-full">
                                <thead className="bg-blue-200 border-b border-gray-500">
                                  <tr>
                                    <th className="table-data py-2 w-5">S.No</th>
                                    <th className="table-data py-2 w-28">Fees Type</th>
                                    <th className="table-data py-2 w-28">Doctor</th>
                                    <th className="table-data py-2 w-28">Fees Name</th>
                                    <th className="table-data py-2 w-16">Amount</th>
                                    <th className="table-data p-1 w-10">
                                      <button onClick={addNewGrid}>{PLUS}</button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(feesStructureGrid || []).map((item, index) => (
                                    <tr key={index}>

                                      <td className="table-data text-center">{index + 1}</td>
                                      <td className="table-data">
                                        <select
                                          disabled={readOnly}
                                          className="w-full rounded py-1 table-data-input"
                                          value={item.labTestTypeId}
                                          onChange={(e) => handleInputFees(e.target.value, index, "labTestTypeId")}
                                        >
                                          <option value="">Select</option>
                                          {labTestTypeData?.data?.map((uom) => (
                                            <option value={uom.id} key={uom.id}>
                                              {uom?.name}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td className="table-data">
                                        <select
                                          disabled={item.labTestTypeId == 1 || readOnly}
                                          className="w-full rounded py-1 table-data-input"
                                          value={item.employeeId}
                                          onChange={(e) => handleInputFees(e.target.value, index, "employeeId")}
                                        >
                                          <option value="">Select</option>
                                          {doctorList?.data?.map((doc) => (
                                            <option value={doc.id} key={doc.id}>
                                              {doc?.name}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td className="table-data">
                                        <select
                                          disabled={readOnly}
                                          className="w-full rounded py-1 table-data-input"
                                          value={item.labTestId}
                                          onChange={(e) => handleInputFees(e.target.value, index, "labTestId")}
                                        >
                                          <option value="">Select</option>
                                          {labTestDetailsData?.data
                                            ?.filter((val) => val.active && parseInt(val.labTestTypeId) === parseInt(item.labTestTypeId))
                                            ?.map((uom) => (
                                              <option value={uom.id} key={uom.id}>
                                                {uom?.name}
                                              </option>
                                            ))}
                                        </select>
                                      </td>
                                      <td className="table-data">
                                        <input
                                          type="text"
                                          className="w-full rounded py-1 px-1 table-data-input"
                                          value={item?.amount || ""}
                                          disabled={readOnly}
                                          onChange={(e) => handleInputFees(e.target.value, index, "amount")}
                                        />
                                      </td>
                                      <td className="table-data text-center">
                                        <button
                                          type="button"
                                          onClick={() => deleteGrid(index)}
                                          className="text-xs text-red-600"
                                        >
                                          {DELETE}
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </fieldset>
                        </div>


                      }

                    </div>
                    <PaymentSuccessModal isOpen={isModalOpen} onClose={closeModal} />
                  </div>
                </div>
              </div>
            </div>
          </div>

        }

        {
          filterByPatient == "INPATIENT" &&
          <InPatientForm patientVisitId={patientVisitId} feesStructureGrid={feesStructureGrid} setFeesStructureGrid={setFeesStructureGrid} patientAdmitId={patientAdmitId} setPatientAdmitId={setPatientAdmitId} needToPay={needToPay}
            setNeedToPay={setNeedToPay} consultFee={consultFee} setConsultFee={setConsultFee} docId={docId} date={date}
            setShowQrPaymentQrCode={setShowQrPaymentQrCode} paymentMode={paymentMode} setPaymentMode={setPaymentMode}
            refNo={refNo} setRefNo={setRefNo} id={id} setId={setId} paymentData={allData} filterByPatient={filterByPatient} setFilterByPatient={setFilterByPatient}
            patientVisitData={patientVisitData} patientAdmitData={patientAdmitData}
          />
        }
      </div>
    </div>
  )
}
