import React, { useEffect, useState } from 'react';
import { DisabledInput, TextInput, DropdownInput } from "../../../Inputs"
import Modal from "../../../UiComponents/Modal";
import { filterByPatientType, paymentModes } from '../../../Utils/DropdownData';
import { getImageUrlPath } from '../../../Constants';
import { DELETE, EMPTY_PROFILE_IMAGE, PLUS } from '../../../icons';
import PaymentSuccessModal from './PaymentSuccessModal ';
import BarcodeGenerator from '../BarCodeGenerator';
import InPatientSelection from './InPatientSelection';
import { useGetPatientAdmitByIdQuery, useGetPatientAdmitQuery } from '../../../redux/pharmaServices/PatientAdmitTransaction';
import { useGetLabTestMasterQuery } from '../../../redux/pharmaServices/LabTestMaster';
import secureLocalStorage from 'react-secure-storage';
import { useGetLabTestTypesQuery } from '../../../redux/pharmaServices/LabTestTypes';
import { dropDownListObject } from '../../../Utils/contructObject';

const InPatientForm = ({ patientVisitId, feesStructureGrid, setFeesStructureGrid, patientAdmitId, setPatientAdmitId, needToPay, setNeedToPay, consultFee, setConsultFee,
    docId, date, setShowQrPaymentQrCode, paymentMode, setPaymentMode, refNo, setRefNo, id, setId, paymentData, filterByPatient, setFilterByPatient, patientVisitData, patientAdmitData }) => {
    const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }

    const [inPatientPopUp, setInPatientPopUp] = useState(false);
    const { data: patientBalanceDetails } = useGetPatientAdmitQuery({ params: { isGetPaymentBalance: true, paymentId: id, patientAdmitId } }, { skip: !patientAdmitId });
    const [readOnly, setReadOnly] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        if (!patientBalanceDetails?.data) return
        setNeedToPay(patientBalanceDetails?.data?.balanceAmount || 0)
    }, [patientBalanceDetails, patientAdmitId, id, setNeedToPay])

    console.log(patientBalanceDetails, "patientBalanceDetails")

    const { data: labTestDetailsData } =
        useGetLabTestMasterQuery({ params });

    const { data: labTestTypeData } =
        useGetLabTestTypesQuery({ params });

    function handleInputFees(value, index, field) {
        const newBlend = structuredClone(feesStructureGrid);
        newBlend[index][field] = value;
        setFeesStructureGrid(newBlend)
    }
    useEffect(() => {
        let totalAmount = feesStructureGrid?.reduce((a, b, index) => a + parseFloat(b.amount), 0)

        setConsultFee(parseFloat(totalAmount).toFixed(2))

    }, [setFeesStructureGrid, feesStructureGrid])


    function deleteGrid(index) {
        setFeesStructureGrid(prev => prev.filter((_, i) => i !== index))
    }
    function addNewGrid() {
        setFeesStructureGrid(prev =>
            [...prev,
            { amount: "", labTestId: "", labTestTypeId: "" }]
        );
    }

    useEffect(() => {
        if (!labTestDetailsData) return
        if (id) return
        let findConsultFeesId = labTestDetailsData?.data?.find(val => val.isConsultFees)?.id
        let findConsultFeesTypeId = labTestDetailsData?.data?.find(val => val.isConsultFees)?.labTestTypeId
        let consultFees = 0;
        if (patientVisitData?.data) {
            consultFees = patientVisitData?.data?.consultFee || 0;
        }
        setFeesStructureGrid(prev => {
            let newArray = Array.from({ length: 1 - prev.length }, i => {
                return { amount: consultFees, labTestId: findConsultFeesId, labTestTypeId: findConsultFeesTypeId }
            })
            return [...prev, ...newArray]
        }
        )
    }, [setFeesStructureGrid, labTestDetailsData, patientVisitData, id])

    return (
        <>
            <Modal

                isOpen={inPatientPopUp}
                onClose={() => setInPatientPopUp(false)}
                widthClass={"px-2 h-[90%] w-[90%]"}

            >
                <InPatientSelection paymentData={paymentData} onClick={(AdmitId) => { setPatientAdmitId(AdmitId); setInPatientPopUp(false) }} patientVisitPaymentId={id} />
            </Modal>
            <div className="flex justify-center mt-3 ">

                <div className="bg-white p-2 rounded-lg w-full h-full shadow-lg  border border-gray-200">


                    <div className="grid grid-cols-1  ">

                        <h2 className="text-3xl font-bold text-center text-emerald-700">
                            Payment
                        </h2>
                        <div className="flex w-full">
                            <div className="flex-grow">
                                <div className="grid grid-cols-3 gap-1">
                                    <div className='col-span-3'>
                                        <fieldset className='frame my-3'>
                                            <legend className='sub-heading'>Payment Details</legend>

                                            <div className="flex  gap-3 ">

                                                <div className="col-span-1 p-2 w-60">
                                                    <DisabledInput name="Payment No" className='w-full' value={docId} readOnly={readOnly} />
                                                </div>
                                                <div className="col-span-1 p-2 ">
                                                    <DisabledInput
                                                        name="Payment Date"
                                                        className='w-full'
                                                        value={date}
                                                        type="Date"
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                                <div className="col-span-1 p-2 w-64">

                                                    <DropdownInput name="Patient Type" options={dropDownListObject(filterByPatientType ? filterByPatientType : "", "show", "value")} value={filterByPatient} setValue={setFilterByPatient} readOnly={readOnly} />
                                                </div>

                                                <div className="flex items-center justify-center">
                                                    <button
                                                        className="bg-yellow-800 hover:bg-green-700 uppercase p-1 rounded-md w-40 text-white text-sm"
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                setInPatientPopUp(true);
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            if (!readOnly) {
                                                                setInPatientPopUp(true);
                                                            }
                                                        }}
                                                    >
                                                        Choose InPatient
                                                    </button>
                                                </div>


                                            </div>
                                        </fieldset>
                                    </div>


                                    {

                                        patientAdmitId &&
                                        < div className='col-span-3'>
                                            <fieldset className='frame my-1 flex w-full space-x-4 p-4overflow-y-auto'>
                                                <legend className='sub-heading'>Patient Admit Details</legend>
                                                <div className="grid grid-cols-4 gap-6 w-full">
                                                    <div className="col-span-1 row-span-4 rounded-lg shadow-md flex flex-col items-center" >
                                                        <div className={`${patientAdmitData?.data?.Patient?.regNo ? "h-[75%]" : "h-full"} p-5 w-[200px]`}>
                                                            {patientAdmitData?.data?.Patient?.image ? (
                                                                <img
                                                                    className="w-[100%] h-[100%] object-fit rounded-lg"
                                                                    src={getImageUrlPath(patientAdmitData?.data?.Patient?.image)}
                                                                    alt="Patient"
                                                                />
                                                            ) : (
                                                                <div className="object-fit w-full h-full flex items-center justify-center bg-gradient-to-r from-gray-200 to-gray-300">
                                                                    <EMPTY_PROFILE_IMAGE height="100%" width="100%" />
                                                                </div>
                                                            )}
                                                            {patientAdmitData?.data?.Patient?.regNo &&
                                                                <div className='h-[25%]'>
                                                                    <BarcodeGenerator isUi value={patientAdmitData?.data?.Patient?.regNo} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='flex gap-5'>
                                                        <DisabledInput
                                                            // name="Room No"
                                                            className='w-full'
                                                            value={patientAdmitData?.data?.patientCurrentRoomDetails?.name || patientAdmitData?.data?.Room?.name}
                                                            required={true}
                                                            readOnly={readOnly}
                                                            tabIndex={3}
                                                        />


                                                        <DisabledInput
                                                            name="Patient Id"
                                                            className='w-full'
                                                            value={patientAdmitData?.data?.Patient?.regNo || ""}
                                                            required={true}
                                                            readOnly={readOnly}
                                                            tabIndex={2}
                                                        />

                                                    </div>

                                                    <DisabledInput
                                                        name="Patient"
                                                        className='w-full'
                                                        value={patientAdmitData?.data?.Patient?.name || ""}
                                                        required={true}
                                                        readOnly={readOnly}
                                                    />

                                                    <DisabledInput
                                                        name="Patient Ph. No"
                                                        className='w-full'
                                                        value={patientAdmitData?.data?.Patient?.mobile || ""}
                                                        required={true}
                                                        readOnly={readOnly}
                                                    />


                                                    <DropdownInput
                                                        name="Pay Mode"
                                                        className="text-sm"
                                                        options={paymentModes}
                                                        value={paymentMode}
                                                        setValue={setPaymentMode}
                                                        required={true}
                                                        readOnly={readOnly}
                                                    />

                                                    {!readOnly && (paymentMode === "Upi") &&
                                                        <div className=" w-full flex items-center ">
                                                            <button className='bg-blue-600 text-white p-1 rounded-sm' onClick={() => { setShowQrPaymentQrCode(true) }}  >
                                                                Show Qr Code
                                                            </button>
                                                        </div>
                                                    }
                                                    <TextInput
                                                        name="Ref No"
                                                        className='w-full'
                                                        type="text"
                                                        value={refNo}
                                                        setValue={setRefNo}
                                                        readOnly={readOnly}
                                                    />

                                                    <TextInput
                                                        name="NeedToPay"
                                                        className='w-full'
                                                        type="number"
                                                        min={0}
                                                        value={needToPay}
                                                    />

                                                    <TextInput
                                                        name="Total.Fees"
                                                        className='w-full'
                                                        type="number"
                                                        min={0}
                                                        value={consultFee}
                                                        // setValue={setConsultFee}
                                                        readOnly={true}
                                                    />

                                                </div>




                                                <div className='w-[40%] p-4 overflow-y-scroll h-[90%]'>

                                                    <div className='sub-heading mt-3 ml-1'>Fees Structure</div>
                                                    <table className=" border border-gray-500 text-xs table-auto  w-full">
                                                        <thead className='bg-blue-200 top-0 border-b border-gray-500'>

                                                            <tr className=''>
                                                                <th className="table-data  py-2 w-5">S.No</th>
                                                                <th className="table-data  py-2 w-28">Fees Type</th>
                                                                <th className="table-data  py-2 w-28">Fees Name</th>

                                                                <th className="table-data  py-2 w-16">Amount</th>



                                                                <th className="table-data  w-10 p-0.5">  <button onClick={addNewGrid}>{PLUS}</button></th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className='overflow-y-auto h-full w-full'>


                                                            {(feesStructureGrid ? feesStructureGrid : []).map((item, index) =>
                                                                <tr className="w-full table-row">
                                                                    <td className='table-data'>
                                                                        {index + 1}
                                                                    </td>
                                                                    <td className='table-data'>
                                                                        <select
                                                                            disabled={readOnly}
                                                                            onKeyDown={e => { if (e.key === "Delete") { handleInputFees("", index, "labTestTypeId") } }}
                                                                            className='text-left w-full rounded py-1 table-data-input'
                                                                            value={item.labTestTypeId}
                                                                            onChange={(e) => handleInputFees(e.target.value, index, "labTestTypeId")}
                                                                        >
                                                                            <option value={""}>
                                                                                Select
                                                                            </option>
                                                                            {labTestTypeData?.data?.map((uom) =>
                                                                                <option value={uom.id} key={uom.id}>
                                                                                    {uom?.name}
                                                                                </option>)}
                                                                        </select>
                                                                    </td>

                                                                    <td className='table-data'>
                                                                        <select
                                                                            disabled={readOnly}
                                                                            onKeyDown={e => { if (e.key === "Delete") { handleInputFees("", index, "labTestId") } }}
                                                                            className='text-left w-full rounded py-1 table-data-input'
                                                                            value={item.labTestId}
                                                                            onChange={(e) => handleInputFees(e.target.value, index, "labTestId")}
                                                                        >
                                                                            <option value={""}>
                                                                                Select
                                                                            </option>
                                                                            {labTestDetailsData?.data?.filter(val => val.active && val.labTestTypeId == item.labTestTypeId)?.map((uom) =>
                                                                                <option value={uom.id} key={uom.id}>
                                                                                    {uom?.name}
                                                                                </option>)}
                                                                        </select>
                                                                    </td>

                                                                    <td className='table-data'>
                                                                        <input
                                                                            type="text"
                                                                            className="text-left rounded py-2 px-1 w-full table-data-input"

                                                                            value={item?.amount ? item?.amount : ""}

                                                                            disabled={readOnly}
                                                                            onChange={(e) =>
                                                                                handleInputFees(e.target.value, index, "amount")
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td className="border border-gray-500 text-xs text-center">
                                                                        <button
                                                                            type='button'
                                                                            onClick={() => {
                                                                                deleteGrid(index)
                                                                            }}
                                                                            className='text-xs text-red-600 '>{DELETE}
                                                                        </button>
                                                                    </td>


                                                                </tr>

                                                            )}

                                                        </tbody>
                                                    </table>


                                                </div>


                                            </fieldset>
                                        </div>

                                    }
                                </div>
                                <PaymentSuccessModal isOpen={isModalOpen} onClose={closeModal} />
                            </div>
                        </div>

                    </div>
                </div>
            </div >


        </>
    )
}

export default InPatientForm