import React, { useEffect, useState, useRef, useCallback } from 'react';

import FormHeader from '../../../Basic/components/FormHeader';
import { toast } from "react-toastify"
import { TextInput, DisabledInput, DropdownInput } from "../../../Inputs"
import ReportTemplate from '../../../Basic/components/ReportTemplate';
import { RetailPrintFormatFinishedGoodsSales } from "..";
import {
  useGetSalesBillQuery,
  useGetSalesBillByIdQuery,
  useAddSalesBillMutation,
  useUpdateSalesBillMutation,
  useDeleteSalesBillMutation,
} from '../../../redux/PharmacyServices/SalesBillService'

import { findFromList, getCommonParams, getDateFromDateTime } from '../../../Utils/helper';
import PoBillItems from './PoBillItems';
import Modal from "../../../UiComponents/Modal";
import PurchaseBillFormReport from './PurchaseBillFormReport';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import LocationDropdown from '../../../ReusableComponents/LocationDropdown';
import StoreDropdown from '../../../ReusableComponents/StoreDropdown';
import StockItemOptionForBarCode from '../../../ReusableComponents/StockItemDropdownForBarCode';

import useSetDefaultLocationStore from '../../../CustomHooks/useSetDefaultLocationStore';
import useIsBarcodeSystem from '../../../CustomHooks/useIsBarcodeSystem';
import SalesTypePharmacyDropdown from '../../../ReusableComponents/SalesTypeDropdown';
import PatientConsultedSelection from './PatientConsultedSelection';
import { useGetPatientVisitByIdQuery } from '../../../redux/pharmaServices/PatientVisitTransaction';
import PrescriptionSelection from './PrescriptionSelection';
import { PDFViewer } from '@react-pdf/renderer';
import tw from "../../../Utils/tailwind-react-pdf";
import PrintFormatSalesBill from '../PrintFormatSalesBill';
import { useGetRoomQuery } from '../../../redux/services/RoomMasterService';
import secureLocalStorage from 'react-secure-storage';
import InPatientSelection from '../Payments/InPatientSelection';
import { useGetPatientAdmitByIdQuery } from '../../../redux/pharmaServices/PatientAdmitTransaction';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import PrintFormatViewer from '../../../Basic/components/PrintFormt/PrintFormatViewer';

const MODEL = "Sales Bill Entry";

export default function Form() {
  const today = new Date()
  const [form, setForm] = useState(true);
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [docId, setDocId] = useState("");
  const [contactMobile, setContactMobile] = useState("");
  const [formReport, setFormReport] = useState(false)
  const [patientVisitPopUp, setPatientVisitPopUp] = useState(false);
  const [prescriptionsPopUp, setPrescriptionsPopUp] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [storeId, setStoreId] = useState("")
  const [roomId, setRoomId] = useState('')
  const [admittedDate, setAdmittedDate] = useState('')
  const [reasonForAdmit, setReasonForAdmit] = useState('')
  const [searchValue, setSearchValue] = useState("");
  const [poBillItems, setPoBillItems] = useState([])
  const childRecord = useRef(0);
  const [refetchStoreId, setRefetchStoreId] = useState(false);
  const [salesType, setSalesType] = useState("Counter")
  const [patientVisitId, setPatientVisitId] = useState('');
  const [patientAdmitId, setPatientAdmitId] = useState('');
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [isOp, setIsOp] = useState(false)
  const [isPatientAdmitPopUp, setIsPatientAdmitPopUp] = useState(false)
  const [pdfUrl, setPdfUrl] = useState(null);
  const barcodeSystem = useIsBarcodeSystem()



  const reportName = "Order.mrt"
  const parameter = `docId=${id}`

  // const viewer = process.env.REACT_APP_VIEWER_URL + `?database=hospitalmanagementsystem&reportName=Order.mrt&project=HMS&docId=${id}`


  const { branchId, finYearId } = getCommonParams()
  pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@2.0.943/build/pdf.worker.min.js';

  const dispatch = useDispatch()
  const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }
  const { data: roomData } = useGetRoomQuery({ params, searchParams: searchValue });
  const { data: allData, isLoading, isFetching } = useGetSalesBillQuery({ params: { branchId, finYearId }, searchParams: searchValue });
  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetSalesBillByIdQuery(id, { skip: !id });

  const [addData] = useAddSalesBillMutation();
  const [updateData] = useUpdateSalesBillMutation();
  const [removeData] = useDeleteSalesBillMutation();
  const componentRef = useRef();

  const getNextDocId = useCallback(() => {

    if (id || isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false);
      if (data?.docId) {
        setDocId(data.docId);
      }
      if (data?.createdAt) setDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
      setContactMobile(data?.contactMobile ? data.contactMobile : "")
      setPoBillItems(data?.SalesBillItems ? data.SalesBillItems : []);
      setStoreId(data?.storeId ? data.storeId : "")
      setLocationId(data?.Store?.locationId ? data?.Store?.locationId : "")
      setName(data?.name ? data?.name : '')
      setSalesType(data?.salesType || 'Counter');
      setPatientVisitId(data?.patientVisitId || '');
      if (!data) {
        setRefetchStoreId(true);
      }
      setPatientAdmitId(data?.patientAdmitId || '');
      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    }, [id])



  useSetDefaultLocationStore({ setLocationId, setStoreId, refetchStoreId, setRefetchStoreId, id })


  const data = {
    id,
    branchId,
    name,
    contactMobile,
    storeId,
    salesBillItems: poBillItems?.filter(item => item?.productId),
    patientVisitId, salesType, finYearId,
    roomNo: roomId,
    reasonForAdmit,
    admittedDate,
    patientAdmitId,
    docId
  }
  const validateData = (data) => {
    return data?.name && data?.contactMobile
      && data?.storeId
      && data?.salesBillItems
      && data?.salesBillItems.length > 0
      && data?.salesBillItems.every(i => i.productId ? i.stockItem : true)
  }
  console.log(singleData, 'singleData');


  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        if (text === "Added") {
          setId(returnData.data?.id);
        } else {
          setId("")
          syncFormWithDb(undefined)
        }
        toast.success(text + "Successfully");
      } else {
        toast.error(returnData?.message)
      }
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });
      dispatch({
        type: `patientVisit/invalidateTags`,
        payload: ['PatientVisit'],
      });
    } catch (error) {
      console.log(error)
    }

  }
  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated")
    } else {
      handleSubmitCustom(addData, data, "Added")
    }
    if (patientVisitId) {
      refetch()
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        await removeData(id).unwrap();
        setId("");
        refetch()
        toast.success("Deleted Successfully");
        dispatch({
          type: `patientVisit/invalidateTags`,
          payload: ['PatientVisit'],
        });
      } catch (error) {
        toast.error("something went wrong")
      }
      ;
    }
  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }
  }

  const onNew = () => {
    getNextDocId();
    setSearchValue("")
    setId("");
    syncFormWithDb(undefined)
    setAdmittedDate('')
    setReasonForAdmit('')
    setRoomId('')
    if (patientVisitId) {
      refetch()
    }
  }


  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }

  // const handlePrint = () => {
  //   setPrintModalOpen(true);
  // }

  const { data: patientVisitData, refetch } = useGetPatientVisitByIdQuery(patientVisitId, { skip: !patientVisitId })


  useEffect(() => {
    if (id) return
    if (!patientVisitData?.data) return
    if (salesType !== "OP") {
      setPoBillItems([])
      setName('')
      setContactMobile('')
      return
    }

    if (!patientVisitId) {
      setPoBillItems([])
      setName('')
      setContactMobile('');
      return
    }
    // if (parseInt(singleData?.data?.patientVisitId || 0) === parseInt(patientVisitId)) return

    setName(patientVisitData?.data?.Patient?.name || '')
    setContactMobile(patientVisitData?.data?.Patient?.mobile || '');
    setPoBillItems(prev => {
      let newItems = structuredClone(prev);
      newItems = [...(patientVisitData?.data?.Prescriptions || []).map(item => ({ productId: item.productId, active: true, productBrandId: item?.Product?.productBrandId, pcsQty: item?.unit || 0 }))]
      return newItems
    })
  }, [patientVisitData, singleData, patientVisitId, salesType, id])

  // useEffect(() => {
  //   if (!patientVisitData?.data) return
  //   setName(patientVisitData?.data?.Patient?.name || '')
  //   setContactMobile(patientVisitData?.data?.Patient?.mobile || '');
  // }, [patientVisitData, id,])


  const { data: patientAdmitData } = useGetPatientAdmitByIdQuery(patientAdmitId, { skip: !patientAdmitId })

  useEffect(() => {
    if (id) return
    if (!patientAdmitData?.data) return
    setName(patientAdmitData?.data?.Patient?.name || '')
    setContactMobile(patientAdmitData?.data?.Patient?.mobile || '');
    setRoomId(patientAdmitData?.data?.roomId || '');
    setReasonForAdmit(patientAdmitData?.data?.reasonForAdmit || '');
    setAdmittedDate(moment(patientAdmitData?.admittedDate).format("DD-MM-YYYY ") || '')
  }, [patientAdmitData, id])

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData,])




  return (
    <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid h-[100%]'>
      <Modal isOpen={printModalOpen} onClose={() => setPrintModalOpen(false)} widthClass={"w-[90%] "}>
        {/* {pdfUrl && (
          <>
            <a download href={viewer}>Pdf </a>
            <a download href={excel}>excel </a>
            <iframe
              src={pdfUrl}
              width="100%"
              height="100%"
              style={{ border: 'none' }}
              title="PDF Viewer"
            /></>
        )} */}
        <PDFViewer style={tw("w-full h-[700px]")}>
          <PrintFormatSalesBill data={singleData?.data ? singleData?.data : undefined} />
        </PDFViewer>
        {/* <PrintFormatViewer pdfUrl={pdfUrl} setPdfUrl={setPdfUrl} id={id} reportName={reportName} parameter={parameter} /> */}
      </Modal>
      <Modal
        isOpen={formReport}
        onClose={() => setFormReport(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <PurchaseBillFormReport onClick={(id) => { setId(id); setFormReport(false) }} />
      </Modal>
      <Modal
        isOpen={patientVisitPopUp}
        onClose={() => setPatientVisitPopUp(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <PatientConsultedSelection salesBillId={id} isOp={isOp} onClick={(id) => { setPatientVisitId(id); setPatientVisitPopUp(false); }} />
      </Modal>
      <Modal
        isOpen={prescriptionsPopUp}
        onClose={() => setPrescriptionsPopUp(false)}
        widthClass={"h-[90%] w-[90%] border-8 border-solid border-gray-500"}>
        <PrescriptionSelection onClose={() => setPrescriptionsPopUp(false)} onClick={() => { setPrescriptionsPopUp(false) }} poBillItems={poBillItems} setPoBillItems={setPoBillItems} medicines={patientVisitData?.data?.Prescriptions || []} />
      </Modal>
      <Modal
        isOpen={isPatientAdmitPopUp}
        onClose={() => setIsPatientAdmitPopUp(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <InPatientSelection onClick={(id) => { setPatientAdmitId(id); setIsPatientAdmitPopUp(false) }} />
      </Modal>
      <div className='flex flex-col frame w-full h-full '>
        <FormHeader
          onNew={onNew}
          model={MODEL}
          openReport={() => setFormReport(true)}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          onPrint={id ? () => {

            setPrintModalOpen(true); // Open the modal
          } : null}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <div className='flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 p-1'>
          <div className='col-span-4 grid md:grid-cols-1 '>
            <div className='mr-1 md:ml-2  h-[100%] overflow-auto frame p-2'>
              {/* <button onClick={() => { previewPdf(viewer) }} > Print</button> */}
              <fieldset className='my-1   frame'>
                <legend className='sub-heading'>Sales</legend>
                <div className='flex flex-wrap flex-col gap-1  justify-start '>
                  <div className='flex'><div className='pt-1 w-56'>  <DisabledInput name="Bill.No" value={docId} required={true} readOnly={readOnly} /></div>
                    <div className='w-36 pt-1'> <DisabledInput name="Bill. Date" value={date} type={"Date"} required={true} readOnly={readOnly} /></div>
                    <div> <LocationDropdown selected={locationId} setSelected={(value) => { setLocationId(value); setStoreId("") }} name={"Location"} multiSelect={false} withoutLabel={false} readOnly={readOnly} /></div>
                    <div className='w-64'><StoreDropdown selected={storeId} setSelected={setStoreId} name={"Store"} multiSelect={false} withoutLabel={false} readOnly={!locationId || readOnly} /></div>
                    <div className='w-64'>   <TextInput name="Name" value={name} setValue={setName} readOnly={readOnly} required /></div>
                    <div className='w-48'>       <TextInput name="Ph No" type={'number'} value={contactMobile} setValue={setContactMobile} readOnly={readOnly} />
                    </div>
                  </div>
                  <div className='flex w-full'>
                    <div className=''> <SalesTypePharmacyDropdown name={"Type"} onBlur={() => {
                      if (salesType === "OP") {
                        if (id) return
                        setPatientVisitPopUp(true);
                        if (patientVisitId) {
                          refetch()
                        }
                      } else {
                        setPatientVisitId("")
                      }
                      if (salesType === "INPATIENT") {
                        if (id) return
                        setIsPatientAdmitPopUp(true);
                      } else {
                        setPatientAdmitId("")
                      }
                    }} selected={salesType} setSelected={setSalesType} readOnly={readOnly} />
                    </div>
                    <div className='flex'>

                      <div className='flex gap-5 '> {salesType === 'INPATIENT'
                        &&
                        < div className='pt-1 flex'>
                          <DisabledInput name="Room" type="text" value={findFromList(roomId, roomData?.data || [], "name")} />
                          <DisabledInput name="Reason For Admit" value={reasonForAdmit} setValue={setReasonForAdmit} readOnly={readOnly} required />
                          <DisabledInput name="Admit Date" value={admittedDate} setValue={setAdmittedDate} readOnly={readOnly} required />
                        </ div>
                      }
                        {(!readOnly) && (salesType === "OP") &&
                          <div className='flex mt-3 gap-3'>
                            <button className='bg-green-500 h-8 text-white text-xs  px-2 rounded ' onKeyDown={(e) => { if (e.key === 'Enter') { setPatientVisitPopUp(true) } }} onClick={() => { setPatientVisitPopUp(true); setIsOp(false) }}>Choose OP </button>
                            <button disabled={!patientVisitId} className={` h-8 text-white text-xs  px-2 rounded ${!patientVisitId ? 'bg-gray-500' : 'bg-blue-500'}`} onKeyDown={(e) => { if (e.key === 'Enter') { setPrescriptionsPopUp(true) } }} onClick={() => { setPrescriptionsPopUp(true) }}>Choose Prescriptions </button>
                          </div>
                        }
                        {
                          (!readOnly) && (salesType === "INPATIENT") &&
                          <button className='bg-green-500 h-8 text-white text-xs  px-2 rounded mt-3' onKeyDown={(e) => { if (e.key === 'Enter') { setPatientVisitPopUp(true) } }} onClick={() => { setIsPatientAdmitPopUp(true); setIsOp(true) }}>Choose IP </button>
                        }</div>

                    </div>

                  </div>
                  <div className='w-64'>  {barcodeSystem &&
                    <StockItemOptionForBarCode storeId={storeId} setPoBillItems={setPoBillItems} />
                  }</div>





                </div>
              </fieldset>
              <PoBillItems storeId={storeId} key={poBillItems} date={singleData?.data?.createdAt} id={id} readOnly={readOnly} poBillItems={poBillItems} setPoBillItems={setPoBillItems} />
              <div className="hidden">
                <RetailPrintFormatFinishedGoodsSales
                  innerRef={componentRef} contactMobile={contactMobile} name={name} date={singleData?.data?.createdAt} id={id} poBillItems={poBillItems} readOnly={readOnly} docId={docId ? docId : ''} />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
