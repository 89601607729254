import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import kmLogo from "../../../assets/hospital.png";
import doctorLogo from "../../../assets/d3.png";
import { ADDRESS, APP_NAME, CITY_ADDRESS, GST_IN, MAIL, MOBILE, STATE_CODE } from '../../../Strings';

const Header = ({ data }) => {
    const logoSize = '100';

    return (
        <View style={tw("w-full flex flex-row")}>
            <View style={tw("flex flex-row justify-start items-center")}>
                <Image src={kmLogo} style={tw(`w-[${logoSize}px]] flex flex-row justify-start `)} />
            </View>
            <View style={tw("flex flex-row items-center w-full justify-center ")}>
                <View style={tw(" flex flex-row font-[Times-Bold] items-center justify-center")}>
                    <View style={tw("flex flex-col items-center")}>
                        <Text style={tw("font-[Times-Bold] text-[15px] text-gray-800 text-center")} >{data?.Branch?.branchName}</Text>
                        <Text style={tw("font-[Times-Bold] text-[9px]")} >  {data?.Branch?.contactAddressBranch}</Text>
                        <Text style={tw("font-[Times-Bold] text-[9px]")} >{data?.Branch?.contactCityBranch}</Text>
                        <Text style={tw("font-[Times-Bold] text-[9px]")} >{data?.Branch?.contactEmail}  -- {data?.Branch?.contactMobile}</Text>

                    </View>

                </View>
            </View>
        </View>
    )
}

export default Header
