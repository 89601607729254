export const APP_NAME = "KC HOSPITAL"

export const ACTIVE = <button className='rounded bg-green-500 border border-green-300 p-1 disabled'>ACTIVE</button>
export const INACTIVE = <button className='rounded bg-red-500 border border-red-300 p-1 disabled'>INACTIVE</button>

export const SUPERADMIN = "SUPERADMIN"
export const ADMIN = "ADMIN"
export const USER = "USER"
export const MEDICAL = "KC MEDICAL"

export const ADDRESS = "NO.33,Railway Feeder Road,"
export const CITY_ADDRESS = "Tirupur, Tamilnadu, 641652"
export const GST_IN = "GST IN• 33BENPSS$$%&M!ZS";
export const STATE_CODE = "STATE NAME:TAMILNADU,CODE:33";
export const MAIL = "manoj@pinnaclesystems.co.in";
export const MOBILE = "+91 9994610733";
export const BANK_NAME = "SBI";
export const ACC_NO = "45645464545455454";
export const BRANCH_NAME = "ANDIPALAYAM, TIRUPUR";
export const IFSC_CODE = "SBIL0001779";
export const G_PAY_NO = "99946 10733";
