
import React from 'react';
import { Document, Page, View, StyleSheet, Text } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import Header from './Header';
import ToField from './ToField';
import Grid from './Grid';
import TaxGrid from './TaxGrid';
import WaterMarkSymbol from '../../../Utils/WaterMarkSymbol';


const PrintFormatSalesBill = ({ data }) => {
    const partyDetails = data?.Party;
    const isTaxBill = data?.isTaxBill;
    const billItems = (data?.SalesBillItems || []).filter(item => item?.qty > 0);

    console.log(data, 'billItems');


    function findDiscount(qty, price, dicount) {
        if (!qty || !price) return 0
        return parseFloat((parseFloat(qty) * parseFloat(price)) * (parseFloat(dicount || 0) / 100)).toFixed(2)
    }



    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            fontSize: 12,
            border: '2px solid black',
            padding: 20,
        },
        header: {
            fontSize: 9,
            marginBottom: 10,
            textAlign: 'center',
        },
        section: {
            margin: 10,
            padding: 10,
        },
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            borderColor: "black"
        },
        tableRow: {
            flexDirection: "row",
            minHeight: '20px'
        },
        tableCol: {
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: 'black',
            borderBottomWidth: 0,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            paddingRight: "1px",
            marginLeft: 3,
            fontSize: 8,
            paddingVertical: 1,
        }
    });
    return (
        <Document style={tw("w-[400px] ")}  >{console.log(data, "dataaaa")}
            <Page size={{ width: 595.28, height: 420.94 }} style={tw("relative w-full border border-black p-1 h-full ")} wrap >
                <WaterMarkSymbol />
                <View style={tw("absolute top-1 bottom-1 left-1 right-1 border border-black")} fixed />
                <View style={tw("bg-pink-300")} fixed>
                    <Header />
                    <ToField partyDetails={partyDetails} data={data} />
                </View>
                <Grid isTaxBill={isTaxBill} billItems={billItems} styles={styles} findDiscount={findDiscount} />
                <TaxGrid taxDetails={billItems} partyDetails={partyDetails} data={data} />
                <Text style={tw("absolute bottom-0 text-center w-full text-xs pb-2")} render={({ pageNumber, totalPages }) => (
                    `Page ${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    );
}

export default PrintFormatSalesBill;
